import { OpeningHours } from '../../components';

export function Home() {
  return (
    <>
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 align-items-md-start g-5 py-5">
          <div className="col d-flex flex-column align-items-start gap-2">
            <h2 className="fw-bold text-body-emphasis">EVEN ANDERS</h2>
            <blockquote className="fw-semibold text-body-secondary fs-4 lh-lg text-justify">
              Hier is alles even anders dan anders. <br />
              Of je nu zin hebt in een knallend kleurtje ijs, een snack met een
              twist of een ontbijt dat je doet glimlachen. <br />
              Kom binnen en ontdek zelf waarom!
            </blockquote>
          </div>

          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              <div className="col d-flex flex-column gap-2">
                <h4 className="fw-semibold mb-0 text-bg-title fs-4 rounded-3 ">
                  Ontbijt
                </h4>
                <img
                  src="images/breakfast.png"
                  alt="actie"
                  className="img-fluid img-thumbnail"
                  width="100%"
                />
              </div>

              <div className="col d-flex flex-column gap-2">
                <h4 className="fw-semibold mb-0 text-bg-title fs-4 rounded-3 ">
                  Snacks
                </h4>
                <img
                  src="images/snacks.png"
                  alt="actie"
                  className="img-fluid img-thumbnail"
                  width="100%"
                />
              </div>

              <div className="col d-flex flex-column gap-2">
                <OpeningHours />
              </div>

              <div className="col d-flex flex-column gap-2">
                <h4 className="fw-semibold mb-0 text-bg-title fs-4 rounded-3 ">
                  Valentijn Actie
                </h4>
                <img
                  src="images/valentijn.jpeg"
                  alt="actie"
                  className="img-fluid img-thumbnail"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
